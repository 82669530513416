import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import Combobox from '@workday/canvas-kit-docs/dist/mdx/react/combobox/Combobox.mdx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Breadcrumbs = makeShortcode("Breadcrumbs");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Breadcrumbs items={[{
      name: 'Resources',
      to: '/get-started/for-developers/resources'
    }, {
      name: 'Combobox'
    }]} mdxType="Breadcrumbs" />
    <p>{`Combobox is an `}<em parentName="p">{`abstract`}</em>{` compound component - it should not be used on its own, but used as a base
to create combobox components. The Combobox system provides components, models, loaders, and
elemProps hooks.`}</p>
    <p>{`The term "Combobox" is based on the
`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/ARIA/apg/patterns/combobox/"
      }}>{`Combobox Pattern`}</a>{` as defined in the ARIA
Authoring Practices Guide (APG):`}</p>
    <blockquote>
      <p parentName="blockquote">{`A `}<a parentName="p" {...{
          "href": "https://w3c.github.io/aria/#combobox"
        }}>{`combobox`}</a>{` is an input widget with an associated popup
that enables users to select a value for the combobox from a collection of possible values.`}</p>
    </blockquote>
    <p>{`Examples of a "combobox" would be date pickers, autocomplete, and select components.`}</p>
    <Combobox mdxType="Combobox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      